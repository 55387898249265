<template>
    <div>
        <a-drawer
        title="作业情况详情"
        placement="right"
        width="600px"
        style="right:300px"
        :closable="false"
        :visible="visible"
        @close="onClose"
        >
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <div class="statistics-detail">
                <div class="statistics-detail-nav">
                    <a-avatar :size="44" icon="user" :src="homework_finish.student_avatar" />
                    <div class="statistics-detail-nav-user">
                        <div>{{homework_finish.student_name}}</div>
                        <div>{{homework_finish.finish_at}}</div>
                    </div>
                </div>
                <div class="statistics-detail-mar">
                    {{homework_finish.finish_content}}
                </div>
                <div class="statistics-detail-mar" v-if="homework_finish.finish_images && homework_finish.finish_images.length > 0">
                    <span @click="showImageEdit(it,1)" v-for='(it,index) in homework_finish.finish_images' :key="index" style="display:inline-block;margin:5px">
                        <LImg style="width:150px;" :src='it'/>
                    </span>
                </div>
                <div class="statistics-detail-mar" v-if="homework_finish.finish_audios && homework_finish.finish_audios.length > 0">
                    <audio v-for='(it,index) in homework_finish.finish_audios' :key="index" :src="it.audio_path" controls />
                </div>
                <div class="statistics-detail-mar statistics-detail-video" v-if="homework_finish.finish_videos && homework_finish.finish_videos.length > 0">
                    <div @click="showVideoModal(it)" v-for='(it,index) in homework_finish.finish_videos' :key="index" class="statistics-detail-video-item">
                        <video style="background:#000;border-radius:2px" :src="it"  width="75" height="75">
                            Your browser does not support the video tag.
                        </video>
                        <div class="statistics-detail-video-icon">
                            <a-icon color='#fff' theme='filled' :style="{ fontSize: '30px', color: '#fff' }" type="play-circle" />
                        </div>
                    </div>
                </div>
                <!-- <a-row type="flex" class="statistics-detail-mar statistics-detail-tab">
                    <a-col :flex="1">
                        <a-icon type="message" />
                        <span>评论</span>
                    </a-col>
                    <a-col :flex="1">
                        <a-icon type="star" />
                        <span>评分</span>
                    </a-col>
                    <a-col :flex="1">
                        <a-icon type="form" />
                        <span>需定正</span>
                    </a-col>
                    <a-col :flex="1">
                        <a-icon type="bulb" />
                        <span>已点亮</span>
                    </a-col>
                    <a-col :flex="1">
                        <a-icon type="bulb" />
                        <span>点亮</span>
                    </a-col>
                </a-row> -->
                <div class="statistics-detail-mar statistics-detail-textarea">
                    <a-textarea
                        v-model="comment_content"
                        placeholder="请输入作业点评"
                        :auto-size="{ minRows: 2, maxRows: 2 }"
                    />
                    <div class="statistics-detail-textarea-send">
                        <a-button @click="toComment" size="small" type="primary">发送</a-button>
                    </div>
                </div>
                <div v-if="comments.length>0" class="statistics-detail-mar statistics-detail-comment">
                    <a-comment v-for="(it,j) in comments" :key="it.commnet_id" :class="j == comments.length - 1?'':'statistics-detail-comment-border'">
                        <a slot="author">{{it.commnet_name}}</a>
                        <!-- <span @click="toReply(item,it,index)" slot="actions">回复</span> -->
                        <a-avatar
                        slot="avatar"
                        :src="it.commnet_avatar"
                        />
                        <p slot="content" @click="showImageEdit(it,2)">
                            {{it.commnet_content}}
                            <LImg v-if="it.comment_image" style="width:75px;display:block;margin-top:5px" :src='it.comment_image'/>
                        </p>
                        <span slot="datetime">{{it.comment_at}}</span>
                        <a-comment v-for="(i,k) in it.commnet_children" :key="i.commnet_id">
                            <a slot="author">{{i.commnet_name}}</a>
                            <!-- <span @click="toReply(item,i,index)" slot="actions">回复</span> -->
                            <a-avatar
                            slot="avatar"
                            :src="i.commnet_avatar"
                            />
                            <p slot="content">
                                {{i.commnet_content}}
                                <LImg v-if="i.comment_image" style="width:45px;height:45px;display:block;margin-top:5px" :src='i.comment_image'/>
                            </p>
                            <span slot="datetime">{{it.comment_at}}</span>
                        </a-comment>
                    </a-comment>
                </div>
            </div>
            <videoModal v-if="isVideoModal" :video='video'/>
            <imageEdit v-if="isImageEdit" :imgsrc="imgsrc" :item="modalData"/>
        </a-drawer>
        
    </div>
</template>

<script>
    import videoModal from '../../review/videoModal'
    import imageEdit from '../../review/imageEdit'
    export default {
        name:'statistics',
        inject: ['parent'],
        props: {
            item: Object
        },
        provide() {
            return {
                parent: this
            }
        },
        data() {
            return {
                comment_content:'',
                visible: false,
                loading: false,
                isImageEdit: false,
                isVideoModal: false,
                myHeight:0,
                rateValue:3,
                modalData:{},
                detailInfo:{},
                comments:[],
                homework_finish:{},
                video:'',
                imgsrc:'',
            }
        },
        components: {
            imageEdit,
            videoModal,
        },
        computed:{
            wHeight(){
                return `${Number(this.myHeight) - 360}px`
            }
        },
        created(){
            this.myHeight = this.windowHeight()
            this.visible = true
            this.getDetail()
        },
        methods:{
            async getDetail(){
                this.loading = true
                await this.$store.dispatch('scheduleHomeworkFinishDetailAction', {record_id:this.item.homework_record_id})
                .then(res=>{
                    this.detailInfo = res.data
                    this.detailInfo.record_id = this.item.homework_record_id
                    this.comments = res.data.homework_comment
                    this.homework_finish = res.data.homework_finish
                    this.loading = false
                })
            },
            toReply(item,it,index){
                this.comment_entity = item.comment_entity
                this.commnet_id = it.commnet_id
                this.commnet_index = index
                this.commnet_name = it.commnet_name
                this.allTextArea = `回复${it.commnet_name} `
            },
            showImageEdit(it,num){
                if(num === 1){
                    this.modalData = this.detailInfo
                    this.imgsrc = it
                    this.isImageEdit = true
                }else{
                    if(it.comment_image){
                        this.modalData = this.detailInfo
                        this.imgsrc = it.comment_image
                        this.isImageEdit = true
                    }
                }
                
            },
            hideImageEdit(val){
                if(val){
                    this.getDetail()
                }
                this.isImageEdit = false
            },
            showVideoModal(src){
                this.video = src
                this.isVideoModal = true
            },
            hideVideoModal(){
                this.isVideoModal = false
            },
            onClose(){
                this.parent.hideDetail()
            },
            async toComment(){
                if(!this.comment_content){
                    this.$message.warning('请填写内容！！')
                    return false
                }
                await this.$store.dispatch('scheduleAddHomeworkAction', {
                    record_id:this.detailInfo.record_id,
                    comment_content:this.comment_content,
                    comment_entity:this.detailInfo.comment_entity,
                })
                .then(res=>{
                    this.$message.success('操作成功~')
                    this.comment_content = ''
                    this.getDetail()
                })
                .catch(err=>{
                    this.$message.error('操作失败~')
                })
            },
            windowHeight() { 
                var de = document.documentElement
                return self.innerHeight||(de && de.offsetHeight)||document.body.offsetHeight
            } 
        }
    }
</script>

<style lang="scss" scoped>
    .statistics-detail{
        &-nav{
            display: flex;
            &-user{
                margin-left: 10px;
            }
        }
        &-mar{
            margin-top: 15px;
        }
        &-tab{
            border: 1px solid #e8e8e8;
            border-radius: 10px;
            .ant-col{
                padding: 10px 0;
                text-align: center;
                cursor: pointer;
                span{
                    margin-left: 5px;
                }
            }
        }
        &-textarea{
            border: 1px solid #e8e8e8;
            padding: 10px;
            border-radius: 10px;
            .ant-input{
                border: none;
                resize: none;
            }
            &-send{
                margin-top: 5px;
                text-align: right;
            }
        }
        &-comment{
            background: #f2f3f4;
            padding: 10px;
            border-radius: 10px;
            &-border{
                p{
                    border-bottom: 1px solid #e8e8e8;
                }
            }
        }
        &-video{
            display: flex;
            align-items: center;
            margin: 10px 0;
        }
        &-video-item{
            position: relative;
        }
        &-video-icon{
            position: absolute;
            top: 23px;
            left: 23px;
            background: #000;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
        }
    }
</style>

<style lang="scss">
    .statistics-detail{
        .messages-comments-border{
            p{
                border-bottom: 1px solid #e8e8e8;
            }
        }
        .ant-comment-actions{
            margin-top: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
        .ant-comment-inner{
                padding-top: 5px;
                padding-bottom: 0px;
                p{
                    margin-bottom:0px;
                    padding-bottom: 5px;
                }
            }
    }
</style>