<template>
    <div>
        <a-drawer
        title="作业情况统计"
        placement="right"
        width="300px"
        :closable="false"
        :visible="visible"
        @close="onClose"
        >
            <div>

                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <div class="statistics">
                    <a-button>完成情况</a-button>
                    <!-- <a-radio-group v-model="radioValue" @change="onChange" button-style="solid"> -->
                        <!-- <a-radio-button value="1">
                        查看情况
                        </a-radio-button> -->
                        <!-- <a-radio-button value="3"> -->
                        <!-- 完成情况 -->
                        <!-- </a-radio-button> -->
                        <!-- <a-radio-button value="2">
                        点评情况
                        </a-radio-button> -->
                    <!-- </a-radio-group> -->
                    <div style="margin:20px 0">
                        <a-progress type="circle" strokeColor="#00cca2" :percent="percent" />
                    </div>
                </div>

                <div>
                    <a-tabs default-active-key="1">
                        <a-tab-pane key="1" :tab="tabTitle">
                            <a-list item-layout="horizontal" :data-source="list.no">
                                <a-list-item slot="renderItem" slot-scope="item, index">
                                    <a-list-item-meta
                                        :description="item.student_name">
                                        <a-avatar
                                        slot="avatar"
                                        :src="item.student_avatar"
                                        />
                                    </a-list-item-meta>
                                </a-list-item>
                            </a-list>
                        </a-tab-pane>
                        <a-tab-pane key="2" :tab="tabTitleF" force-render>
                            <a-list item-layout="horizontal" :data-source="list.yes">
                                <a-list-item @click="showDetail(item)" slot="renderItem" slot-scope="item, index">
                                    <a-list-item-meta
                                        :description="item.student_name">
                                        <a-avatar
                                        slot="avatar"
                                        :src="item.student_avatar"
                                        />
                                    </a-list-item-meta>
                                </a-list-item>
                            </a-list>
                        </a-tab-pane>
                    </a-tabs>
                </div>
            </div>
        </a-drawer>
        <detailDrawer v-if="isDetail" :item="modalData" />
    </div>
</template>

<script>
    import detailDrawer from  './detail'
    export default {
        name:'statistics',
        inject: ['parent'],
        props: {
            item: Object
        },
        provide() {
            return {
                parent: this
            }
        },
        data() {
            return {
                visible: false,
                loading: false,
                isDetail: false,
                radioValue:'1',
                seen:[],
                comment:[],
                finish:[],
                list:{},
                assign:'',
                percent:0,
                myHeight:0,
                tabTitle:'未完成',
                tabTitleF:'已完成',
                modalData: null,
            }
        },
        components: {
            detailDrawer,
        },
        computed:{
            wHeight(){
                return `${Number(this.myHeight) - 360}px`
            }
        },
        created(){
            this.radioValue = this.item.activeIndex
            this.myHeight = this.windowHeight()
            this.visible = true
            this.getList()
        },
        methods:{
            async getList(){
                this.loading = true
                let res = await this.$store.dispatch('homeworkReceiptedAction', {data:{assign_id:this.item.homework_id}})
                this.assign = res.data.assign.length
                this.seen = res.data.seen
                this.comment = res.data.comment
                this.finish = res.data.finish
                this.list = res.data.seen
                this.loading = false
                let top = res.data.seen.yes.length
                this.percent = Math.round(top/this.assign * 100)
            },
            onClose(){
                this.parent.hideStatistics()
            },
            onChange(e){
                if(this.radioValue === '1'){
                    this.list = this.seen
                    this.tabTitle = '未查看'
                    this.tabTitleF = '已查看'
                }else if(this.radioValue === '2'){
                    this.list = this.comment
                    this.tabTitle = '未点评'
                    this.tabTitleF = '已点评'
                }else{
                    this.list = this.finish
                    this.tabTitle = '未完成'
                    this.tabTitleF = '已完成'
                }
                let top = this.list.yes.length
                this.percent = Math.round(top/this.assign * 100)
            },
            showDetail(item){
                this.modalData = item
                this.isDetail = true
            },
            hideDetail(){
                this.isDetail = false
            },
            windowHeight() { 
                var de = document.documentElement
                return self.innerHeight||(de && de.offsetHeight)||document.body.offsetHeight;
            } 
        }
    }
</script>

<style lang="scss" scoped>
    .statistics{
        text-align: center;
    }
    .statistics-item{
        overflow-y: auto;
    }
</style>