<template>
    <a-modal v-model="visible" :footer='null' class="imageEdit"
    cancelText="取消" okText="保存" width="1250px" :closable='false'
    @ok="handleOk" @cancel="handleCancel">
        <div class="imageEdit-bg">
            <tui-image-editor ref="tuiImageEditor" :include-ui="useDefaultUI" :options="options"></tui-image-editor>
            <!-- <div class="imageEdit-nav">
                <a-button :loading="loading" @click="handleOk" type="primary">保存</a-button>
                <a-button @click="handleCancel">取消</a-button>
            </div> -->
            <div class="imageEdit-text">
                <a-textarea
                    style="width:190px"
                    v-model="comment_content"
                    placeholder="发表评语"
                    :auto-size="{ minRows: 3, maxRows: 5 }"
                />
                <div v-if="!loading" class="imageEdit-text-fast" @click="handleOk" >发送点评</div>
                <div v-else class="imageEdit-text-fast">发送中...</div>
            </div>
        </div>
  </a-modal>
</template>

<script>
    import url from '@/utils/URL'
    import createFilePath from '@/utils/tools'
    import 'tui-image-editor/dist/svg/icon-a.svg'
    import 'tui-image-editor/dist/svg/icon-b.svg'
    import 'tui-image-editor/dist/svg/icon-c.svg'
    import 'tui-image-editor/dist/svg/icon-d.svg'
    import 'tui-image-editor/dist/tui-image-editor.css'
    import 'tui-color-picker/dist/tui-color-picker.css'
    import {ImageEditor} from '@toast-ui/vue-image-editor'
    export default {
        name:'imageEdit',
        inject: ['parent'],
        data() {
            return {
                visible: false,
                loading: false,
                useDefaultUI: true,
                options: {
                    includeUI: {
                    loadImage: {
                        path: this.imgsrc,
                        name: 'sampleImage',
                    },
                    locale: {
                        Crop:'裁剪',Apply:'确认',Cancel:'取消',Draw:'画笔',
                        Free:'曲线',Straight:'直线',Color:'颜色',Range:'范围',
                        Shape:'图形工具',Rectangle:'正方形',Circle:'圆形',Triangle:'三角形',
                        Fill:'填充',Stroke:'粗细',Icon:'图标',Arrow:'胖箭头',
                        'Arrow-2':'瘦箭头','Arrow-3':'箭头','Star-1':'五角星','Star-2':'多角星',
                        Polygon:'多边形',Location:'位置',Heart:'心',Bubble:'气泡',
                        'Custom icon':'上传图标',Text:'文字',Bold:'加粗',Italic:'斜体',
                        Underline:'下划线',Left:'左对齐',Center:'居中',Right:'右对齐',
                        'Text size':'字体大小',Rotate:'旋转',Custom:'自由裁剪',Square:'1:1',
                        Flip:'翻转','Flip X':'X轴翻转','Flip Y':'Y轴翻转',Reset:'重置',
                        Undo:'撤销',Redo:'恢复',Delete:'删除',DeleteAll:'全部删除'
                    },
                    initMenu:['draw'],
                    menuBarPosition:'right'
                    },
                    cssMaxWidth: 900,
                    cssMaxHeight: 700,
                    selectionStyle: {
                        cornerSize: 5
                    }
                },
                uploadUrl: url.uploadQiNiu,
                comment_content:'',
            }
        },
        components: {
            'tui-image-editor': ImageEditor
        },
        props:{
            item:Object,
            imgsrc:String
        },
        created(){
            this.visible = true
            console.log(this.imgsrc)
        },
        methods:{
            async handleOk(){
                this.loading = true
                let tuiImageEditor = this.$refs.tuiImageEditor
                let data = tuiImageEditor.invoke('toDataURL')
                let name = createFilePath('ceshi.png')
                let file = this.dataURLtoFile(data, name)
                let res = await this.$store.dispatch('imageTokenAction', {})
                let formData = new FormData()
                formData.append('key', name)
                formData.append('token', res.data.uptoken)
                formData.append("file", file)
                await this.$store.dispatch('uploadQiNiuAction', formData)
                .then(result=>{
                    this.addHomework(result.key)
                })
            },
            async addHomework(img){
                await this.$store.dispatch('scheduleAddHomeworkAction', {
                    record_id:this.item.record_id,
                    comment_content:this.comment_content,
                    comment_entity:this.item.comment_entity,
                    comment_image:img
                })
                .then(res=>{
                    this.$message.success('操作成功~')
                    this.parent.hideImageEdit(1)
                })
                .catch(err=>{
                    this.$message.error('操作失败~')
                    this.loading = false
                })
            },
            handleCancel(){
                this.parent.hideImageEdit()
            },
            dataURLtoFile: function(dataurl, filename) { 
                var arr = dataurl.split(','),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], filename, { type: mime });
            },

        }
    }
</script>

<style lang="scss">
    .imageEdit .ant-modal-content{
        background-color: rgba(0,0,0,0);
        box-shadow:none;
    }
    .imageEdit .ant-modal-body{
        max-height: calc(100vh - 15px);
    }
    .imageEdit-bg{
        background: #000;
        width: 1200px;
        height: 750px;
        position: absolute;
        .tui-image-editor-header{
            display: none;
        }
        .tie-btn-filter{
            display: none !important;
        }
        .imageEdit-nav{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            width: 92%;
            height: 56px;
            text-align: right;
            line-height: 56px;
            .ant-btn{
                margin-right: 10px;
            }
        }
        .imageEdit-text{
            position: absolute;
            bottom: 40px;
            right: 95px;
            z-index: 10;
            &-fast{
                color: #fff;
                text-align: right;
                margin-bottom: 10px;
                cursor: pointer;
            }
            textarea{
                resize: none;
            }
        }
    }
</style>